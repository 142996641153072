import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/About",
    // name: "Auth",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/Auth",
    // name: "Auth",
    component: () => import("../layouts/Auth.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("../views/Login.vue"),
      },
    ],
  },
  {
    path: "/",
    // name: "",
    component: () => import("../layouts/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/SettingBranch",
        name: "SettingBranch",
        component: () => import("../views/settings/branch/settingBranch/SettingBranch.vue"),
      },
      {
        path: "/SettingBranchPdt",
        name: "SettingBranchPdt",
        component: () => import("../views/settings/branch/settingBranchPdt/SettingBranchPdt.vue"),
      },
      {
        path: "/SettingUser",
        name: "SettingUser",
        component: () => import("../views/settings/user/settingUser/SettingUser.vue"),
      },
      {
        path: "/SettingPMS",
        name: "SettingPMS",
        component: () => import("../views/settings/pms/settingPMS/SettingPMS.vue"),
      },
      {
        path: "/SettingUserPMS",
        name: "SettingUserPMS",
        component: () => import("../views/settings/pms/settingUserPMS/SettingUserPMS.vue"),
      },
      {
        path: "/SettingBranchPMS",
        name: "SettingBranchPMS",
        component: () => import("../views/settings/pms/settingBranchPMS/SettingBranchPMS.vue"),
      },
      {
        path: "/SettingAgentPMS",
        name: "SettingAgentPMS",
        component: () => import("../views/settings/pms/settingAgentPMS/SettingAgentPMS.vue"),
      },
      {
        path: "/RptAgentSum",
        name: "RptAgentSum",
        component: () => import("../views/report/rptAgent/RptAgentSum.vue"),
      },
      {
        path: "/RptAgentSumDetail",
        name: "RptAgentSumDetail",
        component: () => import("../views/report/rptAgent/RptAgentSumDetail.vue"),
      },
      {
        path: "/RptAgentSumBranch",
        name: "RptAgentSumBranch",
        component: () => import("../views/report/rptAgent/RptAgentSumBranch.vue"),
      },
      {
        path: "/RptVisitSum",
        name: "RptVisitSum",
        component: () => import("../views/report/rptVisit/RptVisitSum.vue"),
      },
      {
        path: "/RptVisitAvg",
        name: "RptVisitAvg",
        component: () => import("../views/report/rptVisit/RptVisitAvg.vue"),
      },
      {
        path: "/RptGuideTop",
        name: "RptGuideTop",
        component: () => import("../views/report/rptGuide/RptGuideTop.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  publicPathmode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  routes,
});

export default router;
