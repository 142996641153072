import Axios from "axios";
import router from "@/router";

const actions = {
  actCheckAuth({ commit }) {
    commit("SET_USER_EMPTY");
    router.push("/Auth").catch(() => {});
  },
  actLogout({ commit }) {
    commit("SET_USER_EMPTY");
    router.push("/Auth").catch(() => {});
  },
  async actLogin({ commit }, data) {
    return await Axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, data)
      .then((response) => {
        if (response.status == 200 && response.data.status) {
          //console.log(response.data);
          commit("SET_USER", response.data.result);
        }
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actNewUserPassword(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user/new_user_pass`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actChangePassword(store, data) {
    const config = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user/change_pass`,
      data, config
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async getUserPermissions(store) {
    const config = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user/get_pms`,
      config
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export default actions;
