import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

import auth from "./modules/auth/index";
import settings from "./modules/settings/index";
import rpt from "./modules/rpt/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    rpt,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ], //ต้องใส่เพื่อให้ store จำค่า ตอน refresh
});
