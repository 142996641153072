export const newUser = () => {
  return {
    ft_username: "",
    ft_name: "",
    ft_password: "",
    fn_product_type: "",
    fn_pms_group: "",
    ft_pms_select: "",
    fd_start_date: "",
    fn_status_active: 1,
    token:"",
  };
};

const state = () => ({
  user: newUser(),
});

export default state;
