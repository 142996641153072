import Axios from "axios";
// import router from "@/router";

const actions = {
  async actUserBranchGets(store) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user/get_branch`, options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },


  async getAllPermissions(store) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return Axios.get(`${process.env.VUE_APP_API_URL}/pms/get_all`, config)
      .then(response => response)
      .catch(error => error.response);
  },
  async createPermission(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/pms/add`,
      payload,
      config
    ).catch(error => error.response);
  },
  async updatePermission(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/pms/edit`,
      payload,
      config
    ).then(response => response).catch(error => error.response);
  },
  async getBranches(store, active) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/branch/get_all?active=${active}`, options
    ).then(response => response)
      .catch(error => error.response);
  },
  async createBranch(store, branchData) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/branch/add`,
      branchData,
      config
    ).then(response => response)
      .catch(error => error.response);
  },
  async updateBranch(store, branchData) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/branch/edit`,
      branchData,
      config
    ).then(response => response)
      .catch(error => error.response);
  },
  async getBranchProducts(store) {
    const config = {
      headers: {
        Authorization: `Bearer ${store.rootState.auth.user.token}`,
      },
    };

    return await Axios
      .get(`${process.env.VUE_APP_API_URL}/branch_pdt/get_all`, config)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createBranchProduct(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/branch_pdt/add`,
      payload,
      config
    ).catch((error) => error.response);
  },
  async updateBranchProduct(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/branch_pdt/edit`,
      payload,
      config
    ).catch((error) => error.response);
  },
  async getUsers(store, active) {
    const { token } = store.rootState.auth.user;
    const options = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user/get_all?active=${active}`,
      options
    ).then(response => response)
      .catch(error => error.response);

  },
  async createUser(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user/add`,
      payload,
      config
    )
      .then(response => response)
      .catch(error => error.response);
  },
  async updateUser(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user/edit`,
      payload,
      config
    )
      .then(response => response)
      .catch(error => error.response);
  },
  async getUserPermissions(store) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user_pms/get_all`,
      config
    ).catch((error) => error.response);
  },
  async createUserPermission(store, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${store.rootState.auth.user.token}`,
      },
    };

    return Axios.post(
      `${process.env.VUE_APP_API_URL}/user_pms/add`,
      payload,
      config
    );
  },
  async updateUserPermission(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user_pms/edit`,
      payload,
      config
    ).then(response => response)
      .catch(error => error.response);
  },
  async getUserBranch(store) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user_branch/get_all`,
      config
    ).catch((error) => error.response);
  },
  async createUserBranch(store, payload) {
    const token = store.rootState.auth.user.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user_branch/add`,
      payload,
      config
    ).catch((error) => error.response);

  },
  async updateUserBranch(store, payload) {
    const { token } = store.rootState.auth.user;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user_branch/edit`,
      payload,
      config
    ).then(response => response)
      .catch(error => error.response);
  },
  async getUserAgent(store) {
    const config = {
      headers: {
        Authorization: `Bearer ${store.rootState.auth.user.token}`
      }
    };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user/get_agent`,
      config
    )
      .then(response => response)
      .catch(error => error.response);
  },
  async getAllUserAgent(store) {
    const config = {
      headers: {
        Authorization: `Bearer ${store.rootState.auth.user.token}`
      }
    };

    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/user_agent/get_all`,
      config
    )
      .then(response => response)
      .catch(error => error.response);
  },
  async createUserAgent(store, payload) {
    const token = store.rootState.auth.user.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user_agent/add`,
      payload,
      config
    ).catch((error) => error.response);

  },
};

export default actions;
