import Axios from "axios";
// import router from "@/router";

const actions = {
  async actRptAgentSum(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/report/agent_sum`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actRptAgentSumDetail(store, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${store.rootState.auth.user.token}`,
      },
    };
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/report/agent_sum_detail`,
      payload,
      config
    ).catch((error) => error.response);
  },
  
  async actRptVisitSum(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);

    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/report/visit_sum`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actRptVisitAvg(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/report/visit_avg`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actRptGuideTop(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/report/guide_top`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actTempAgentSumRpt(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tmp_agent_sum/get_rpt`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actTempAgentSumAdd(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tmp_agent_sum/add`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async actTempAgentSumDelete(store, data) {
    const options = {
      headers: { Authorization: `Bearer ${store.rootState.auth.user.token}` },
    };
    // console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tmp_agent_sum/delete`,
      data,
      options
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export default actions;
