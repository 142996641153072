export const newBranch = () => {
  return {
    ft_code: "",
    ft_name: "",
    ft_name_rpt: "",
    ft_company: "",
    ft_tax_id: "",
    ft_address: "",
    ft_tel: "",
    ft_fax: "",
    ft_dyn: "",
    ft_ip: "",
    ft_fdb: "",
    ft_fdb_doc: "",
    fn_status_active: 1,
  };
};

export const newBranchPdt = () => {
  return {
    fn_branch_id: "",
    ft_pdt_type_name: "",
    ft_rpt_value: "",
  };
};

export const newPMSGroup = () => {
  return {
    fn_pms_grp_id: "",
    ft_name: "",
    ft_name_th: "",
    ft_init: "",
    fn_page: "",
    fn_status_active: 1,
  };
};

export const newPMS = () => {
  return {
    fn_pms_id: "",
    ft_name: "",
    ft_path_grp: "",
    ft_path: "",
    ft_title: "",
    ft_icon: "",
    fn_show_no: "",
  };
};

export const newUserPMS = () => {
  return {
    ft_username: "",
    fn_pms_id: "",
  };
};

export const newUserBranch = () => {
  return {
    ft_username: "",
    fn_branch_id: "",
  };
};

export const newUserAgent = () => {
  return {
    ft_username: "",
    fn_branch_id: "",
    fn_agent_id: "",
    ft_agent_code: "",
  };
};

export const newSysNumberFormat = () => {
  return {
    TableName: "",
    StartChar: "",
    YearFormat: 0,
    MonthFormat: 0,
    DateFormat: 0,
    NumLen: 1,
    NumberFormat: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

const state = () => ({
});

export default state;
